export const environment = {
  production: true,
  console: false,
  API_URL: "https://apipre.apiacpos.com/2_3_0_bridge_zaragoza",  
  CODIGO_APLICACION: "CUSTOMAN",
  ID_APLICACION: "19",
  chClaveEmpresa: "AYTO_ZGZ_FORO",
  TICKETS_URL: "",
  chProyectoOnLine: "VENTAONLINE",
  chEntornoConexion: "PREPRODUCCION",
  chNombreProyecto: "CUSTOMMAN",
  chOrigenConexion: "CUSTOMMAN",
  NUMSERIE: "CUSTO$A$SZ45$A$MMAN$A$23g!."
  
};
